import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p><b>
          Work in progress!<br/> In the meantime, get in touch at <a
          className="App-link"
          href="mailto:ashley@ahheducation.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          ashley@ahheducation.co.uk
        </a>
        .</b>
        </p>
        
      </header>
    </div>
  );
}

export default App;
